@import '@/assets/scss/_variables.scss';
.CreateAndUpdateProvider_CurrenciesList {
  margin: 0 0 24px;

  &--error {
    .Checkbox_checkmark {
      border-color: #ff556d;
    }
  }

  &_Title {
    font-size: 14px;
    line-height: 16px;
    padding: 0 0 9px;
    margin: 0 0 16px;
    border-bottom: 1px solid rgba(#2AF3F3, .1);
    text-transform: uppercase;
  }

  &_Items {
    display: grid;
    row-gap: 12px;
    column-gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));

    @media only screen and (min-width: $tablet-width) {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
  }

  &_Item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &Checkbox {
      &.Checkbox_root {
        height: 18px;
        padding: 0 0 0 18px;
        margin: 0 10px 0 0;
      }
    }

    &Icon {
      margin: 0 6px 0 0;
    }

    &Title {
      font-size: 12px;
      line-height: 14px;

      @media only screen and (min-width: $tablet-width) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}