@import '@/assets/scss/_variables.scss';
.CreateAndUpdateProvider_Info {
    padding: 22px 10px;
    background: rgba(#102032, .5);
    box-shadow: inset 0px 8px 10px rgba(34, 39, 47, 0.14);
    border-radius: 4px;
    @media only screen and (min-width: $tablet-width) {
        padding: 22px 20px;
    }
    &Title {
        margin: 0 0 8px;
        font-size: 12px;
        line-height: 14px;
        color: #2DCA73;
        .CreateAndUpdateProvider_InfoText + & {
            margin: 22px 0 8px;
        }
    }
    &Label {
        font-size: 10px;
        line-height: 14px; 
        color: #9395AB;
    }
    &Text {
        margin: 0 0 7px;
        font-weight: 500;
        font-size: 11px;
        line-height: 15px;
        color: #fff;
        &:last-child {
            margin: 0;
        }
    }
    &Number {
        font-size: 16px;
        line-height: 22px;
    }
}